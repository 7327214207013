@import 'themes/main.css';

@import 'sanitize.css';

html {
	height: 100vh;
}

body {
	display: block;
	font-family: sans-serif;
	lost-align: middle-center;
	height: 100%;
}

::selection {
	background-color: #3f2e89;
	color: white;
}

a {
	color: #3f2e89;
	&:hover {
		color: #5844a2;
	}
}

section.logo {

	& h1 {
		background-image: url('images/logo.svg');
		background-size: calc(var(--size-20)*12) calc(var(--size-5)*12);
		width: calc(var(--size-20)*12);
		height: calc(var(--size-5)*12);
		text-indent: calc(var(--size-20)*12);
		overflow: hidden;
		white-space: nowrap;
		margin-left: calc(var(--size-unit) * -39);
	}
}


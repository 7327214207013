@custom-media --width-narrow (width <= 752px); /* 720 + 16*2 for scollbars */
@custom-media --width-normal-min (width > 752px);
@custom-media --width-normal (width > 752px) and (width < 1024px);
@custom-media --width-normal-max (width < 1024px);
@custom-media --width-wide (width >= 1024px);

@custom-media --height-short (height <= 576px);
@custom-media --height-normal-min (height > 576px);
@custom-media --height-normal (height > 576px) and (height < 664px);
@custom-media --height-normal-max (height < 664px);
@custom-media --height-high (height >= 664px);

/* 16:9 = 100vw : 56.25vw */
@custom-media --portrait (height > calc(56.25vw + 7rem)); /* 7rem = size-56 */
@custom-media --landscape (height <= calc(56.25vw + 7rem)); /* 7rem = size-56 */

body {
	min-width: 360px;
	min-height: 203px; /* ~360/16*9 */
}

:root {
	font-size: 16px;

	/* --breakpoint-width-wide:--breakpoint-height-normal = 16:9 */
	--breakpoint-width-normal: 720px;
	--breakpoint-width-wide: 1024px;
	--breakpoint-height-normal: 576px;
	--breakpoint-height-high: 664px;

	--animation-duration-short: 0.2s;
	--animation-duration-medium: 0.3s;
	--animation-duration-long: 0.5s;
	--animation-delay-visibility: 0.2s;
	--animation-delay-ui: 2s;
	--animation-disabled: 0s;

	/* common sizes */
	--size-unit: 0.125rem;

	--size-1: calc(1 * var(--size-unit));
	--size-2: calc(2 * var(--size-unit));
	--size-3: calc(3 * var(--size-unit));
	--size-4: calc(4 * var(--size-unit));
	--size-5: calc(5 * var(--size-unit));

	--size-6: calc(6 * var(--size-unit));
	--size-8: calc(8 * var(--size-unit));
	--size-10: calc(10 * var(--size-unit));
	--size-12: calc(12 * var(--size-unit));
	--size-14: calc(14 * var(--size-unit));
	--size-16: calc(16 * var(--size-unit));
	--size-18: calc(18 * var(--size-unit));

	--size-20: calc(20 * var(--size-unit));
	--size-24: calc(24 * var(--size-unit));
	--size-28: calc(28 * var(--size-unit));
	--size-32: calc(32 * var(--size-unit));
	--size-36: calc(36 * var(--size-unit));
}
